<template>
  <b-container>
    <b-row class="mb-5 pt-5 text-start">
      <b-col><curva-title :title="$t('blogs.blogs')" class="mb-5"></curva-title></b-col>
    </b-row>
    <b-row id="blogs-list" class="mb-5" v-if="blogsList.length > 0">
      <b-col
          lg="3" md="6"
        v-for="(blog, key) in blogsList"
        :key="key"
        class="mb-4"
      >
        <blog-card :blog="blog" />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="d-flex justify-content-center">
        <b-pagination
          class="curva-pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @input="getBlogs()"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import BlogsServices from '@/modules/blogs/services/blogs.services'

export default {
  props: {
    blogsList: {
      type: Array
      /* default: () => [
        {
          title: 'Real Madrid announces the appointment of Ancelotti as coach for 3 seasons, replacing Zidane',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        },
        {
          title: 'Tempo .. the Egyptian football that imposed its control over the African and Asian leagues',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        },
        {
          title: 'Badji informs Al-Ahly of his desire to continue in Europe',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        },
        {
          title: 'Badji informs Al-Ahly of his desire to continue in Europe',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        },
        {
          title: 'Badji informs Al-Ahly of his desire to continue in Europe',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        },
        {
          title: 'Badji informs Al-Ahly of his desire to continue in Europe',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        },
        {
          title: 'Badji informs Al-Ahly of his desire to continue in Europe',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        }] */
    }
  },
  data () {
    return {
      perPage: 0,
      currentPage: 1,
      rows: 1
    }
  },
  methods: {
    getBlogs () {
      window.scrollTo(0, 0)
      this.loading = true
      BlogsServices.getBlogs(this.currentPage).then((res) => {
        this.rows = res.data.meta.total
        this.perPage = res.data.meta.per_page
        this.currentPage = res.data.meta.current_page
        this.blogsList = res.data.data
      })
    }
    // itemsForList () {
    //   return this.blogsList.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
    // }
  },
  created () {
    this.getBlogs()
  },
  mounted () {
    core.index()
  }
}
</script>
